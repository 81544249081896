<template>
  <div>
    <div class="position-relative">
      <doughnut-chart :chartdata="chartData" :options="options" :height="chartHeight" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Doughnut } from 'vue-chartjs'
export default {
  name: 'SendingCategoryChart',
  components: {
    'doughnut-chart': Doughnut,
  },
  props: {
    chartHeight: Number
  },
  data: function () {
    const chartData = {}

    const options = {
      elements: {
        arc: {
          borderColor: '#4BC0C0',
          hoverBorderColor: 'black',
        },
      },
      legend: {
        position: 'left',
        rtl: 'true',
        labels: {
          fontSize: 14,
        },

      },
      animation: {
        animateRotate: 'true',
      }
    }
    return {
      chartData,
      options,
    }
  },
  computed: {
    ...mapState('admin', [
      'orderedProductsDetails',
    ]),
  },

  methods: {
    triggerUpdateChart() {
      this.updateChart(this.getProductsOrderedPerCategory(this.orderedProductsDetails))
    },

    updateChart(qtyPerCategory) {
      const catList = []
      const quantity = []
      const bColor = []
      qtyPerCategory.forEach(category => {
        catList.push(category.catName)
        quantity.push(category.qty)
        bColor.push(this.random_rgb())
      })

      this.chartData = {
        datasets: [
          {
            data: quantity,
            backgroundColor: bColor,
          },
        ],
        labels: catList,
      }
      this.$children[0].renderChart(this.chartData, this.options)
    },

    getProductsOrderedPerCategory(orderDetails) {
      const groupCategories = []
      // const orderedProducts = Object.values(orderDetails);
      const orderedProducts = orderDetails || []

      // Grouping Products and adding the ordered quantity to get total
      orderedProducts.reduce((res, value) => {
        const prodItems = value.data.product_items || []
        prodItems.forEach((prodItem) => {
          const prodCat = prodItem.c_productCategory || 'Unknown Category'
          if (!res[prodCat]) {
            res[prodCat] = { catName: prodCat, qty: 0 }
            groupCategories.push(res[prodCat])
          }
          res[prodCat].qty += prodItem.quantity
        })
        return res
      }, {})

      // Sorting based on category name
      // groupCategories.sort((a, b) => (a.catName > b.catName) ? 1 : -1)

      // Sorting based on quantiy
      groupCategories.sort((a, b) => (b.qty > a.qty) ? 1 : -1)

      // Displaying only top 12 categories
      groupCategories.length = 12

      return groupCategories
    },

    random_rgba() {
      const o = Math.round
      const r = Math.random
      const s = 255
      return 'rgba(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ',' + r().toFixed(1) + ')'
    },

    random_rgb() {
      const o = Math.round
      const r = Math.random
      const s = 255
      return 'rgb(' + o(r() * s) + ',' + o(r() * s) + ',' + o(r() * s) + ')'
    },

  },

  watch: {
    orderedProductsDetails() {
      this.triggerUpdateChart()
    }
  },
}
</script>
